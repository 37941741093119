<template>
  <v-card flat>
    <loading-bar :value="loading"></loading-bar>
    <v-container fluid class="pa-0">
      <!-- HOME SELECT -->
      <v-row dense v-if="theme">
        <v-col cols="12">
          <v-select
            :items="homeChoices"
            v-model="home"
            :disabled="!edit"
            label="My Home Page"
            color="color3"
            item-color="color3"
          ></v-select>
        </v-col>
      </v-row>
      <!-- OTHER HOME TEXTBOX -->
      <v-expand-transition>
        <v-row dense v-if="home === 'other'">
          <v-col cols="12">
            <v-text-field
              label="Alternate home page"
              :disabled="!edit"
              color="color3"
              v-model="alt"
              hint="Must be a relative URL i.e. /tournament/630"
              persistent-hint
            ></v-text-field>
          </v-col>
        </v-row>
      </v-expand-transition>
      <!-- CAROUSEL IMAGES -->
      <v-expand-transition>
        <v-row v-if="!home">
          <v-col cols="12">
            <v-card flat color="grey lighten-3">
              <div :class="{'block': !edit}"></div>
              <v-card-text>
                <div class="v-input v-label v-label--active theme--light">Home Page Carousel Images</div>
                <image-library
                  ref="library"
                  :images="images"
                  :username="username"
                  :noPreview="true"
                  :singleRow="true"
                  :imgHeight="100"
                  :imgWidth="300"
                  :cover="true"
                  :noUpload="true"
                  @preview-click="(pl) => { slide = pl.n }"
                />
              </v-card-text>
              <v-expand-transition>
                <v-col cols="12" v-if="add && slide === null" class="grey lighten-2">
                  <div>Select an image below or upload a new one</div>
                  <organization-image-library
                    :username="username"
                    :noPreview="true"
                    :singleRow="true"
                    :imgHeight="100"
                    :imgWidth="300"
                    :cover="true"
                    @preview-click="addImage"
                    @image-saved="addImage"
                  ></organization-image-library>
                </v-col>
              </v-expand-transition>
              <v-expand-transition>
                <v-col v-if="slide !== null">
                  <v-window v-model="slide">
                    <v-window-item
                      v-for="(img, n) in carouselItems"
                      :key="n"
                    >
                      <v-card color="grey lighten-4">
                        <v-card-title>
                          Image {{slide + 1}}
                        </v-card-title>
                        <v-card-actions>
                          <v-btn
                            color="color3 color3Text--text"
                            class="mx-3"
                            @click.stop="moveImg(n, n - 1)"
                            :disabled="slide === 0"
                          >
                            <v-icon class="mr-2">fas fa-caret-left</v-icon>
                            Move to {{slide}}
                          </v-btn>
                          <v-btn
                            color="color3 color3Text--text"
                            @click.stop="moveImg(n, n + 1)"
                            :disabled="n === carouselItems.length - 1"
                          >
                            Move to {{slide + 2}}
                            <v-icon class="ml-2">fas fa-caret-right</v-icon>
                          </v-btn>
                          <v-btn
                            color="error white--text"
                            @click.stop="removeImg(n)"
                          >
                            Delete
                            <v-icon class="ml-2" small>fas fa-trash</v-icon>
                          </v-btn>
                        </v-card-actions>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12">
                              <v-checkbox label="Contain" v-model="img.contain" color="success" hide-details></v-checkbox>
                              <!-- POPUP MENU -->
                              <v-menu
                              v-if="false"
                                v-model="showMenu"
                                absolute
                                offset-y
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn :color="img.color" small fab v-on="on" v-bind="attrs">{{ img.color }}</v-btn>
                                </template>
                                <v-color-picker
                                  mode="hex"
                                  v-model="img.color"
                                ></v-color-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field
                                label="Action Text"
                                v-model="img.actionText"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field
                                label="Action URL"
                                v-model="img.actionPath"
                                hint="Must be a relative URL i.e. /tournament/630"
                                persistent-hint
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field
                                label="Headline"
                                v-model="img.headline"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field
                                label="Sub-headline"
                                v-model="img.subheadline"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="user && user.vbl">
                              <v-text-field
                                label="Background Color"
                                v-model="img.color"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-window-item>
                  </v-window>
                </v-col>
              </v-expand-transition>
            </v-card>
          </v-col>
          <v-expand-transition>
            <v-col cols="12" v-if="edit">
              <v-btn color="color3 color3Text--text" class="mr-3" @click="add = true; slide = null">Add Another</v-btn>
              <v-btn color="color3Text color3--text" @click.stop="preview = !preview">
                {{preview ? 'hide' : 'show'}} preview
                <v-icon class="ml-2">fas fa-eye</v-icon>
              </v-btn>
            </v-col>
          </v-expand-transition>
        </v-row>
      </v-expand-transition>
      <!-- CAROUSEL PREVIEW -->
      <v-expand-transition>
        <v-row dense v-if="preview">
          <v-col cols="12">
            <v-carousel
              :show-arrows="false"
              cycle
              continuous
            >
              <v-carousel-item
                v-for="(item,i) in previewItems"
                :key="i"
                :src="item.src"
                :contain="item.contain"
              >
                <v-row class="fill-height" align="end" style="padding-bottom: 50px" v-if="item.action">
                  <v-col class="text-right" cols="12" >
                    <v-btn color="color3Text color3--text" :to="item.action.to">{{item.action.text}}</v-btn>
                  </v-col>
                </v-row>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
      </v-expand-transition>
      <!-- EXCLUDES -->
      <v-row dense>
        <v-checkbox
          class="mr-2"
          label="Hide Rankings"
          v-model="excludes"
          value="rankings"
          color="color3"
          :disabled="!edit"
        ></v-checkbox>
        <v-checkbox
          label="Hide Point Systems"
          v-model="excludes"
          value="points"
          color="color3"
          :disabled="!edit"
        ></v-checkbox>
      </v-row>
      <!-- EDIT BUTTONS -->
      <v-row dense justify="end">
        <v-fab-transition>
          <v-btn
            color="success white--text"
            key="save"
            v-if="edit && (dirty.theme || dirty.carousel || !!saving.length)"
            fab
            small
            class="my-0 mr-1"
            @click.stop="save"
            :loading="!!saving.length"
          >
            <v-icon small>fas fa-save</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            :color="activeFab.color"
            :key="activeFab.icon"
            fab
            small
            class="my-0"
            @click.stop="edit ? cancel() : editMe()"
          >
            <v-icon small>{{activeFab.icon}}</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import * as actions from '@/store/ActionTypes'
import ImageLibrary from '@/components/Utils/ImageLibrary.vue'
import OrganizationImageLibrary from '@/components/Organization/OrganizationImageLibrary.vue'

import { mapGetters } from 'vuex'

export default {
  props: ['username'],
  data () {
    return {
      panel: [0],
      theme: null,
      og: null,
      loading: false,
      home: null,
      alt: null,
      edit: false,
      saving: [],
      carouselItems: [],
      carouselItemsOG: [],
      preview: false,
      slide: null,
      add: false,
      excludes: [],
      showMenu: false
    }
  },
  computed: {
    ...mapGetters(['getTheme', 'user']),
    activeFab () {
      return this.edit ? { color: 'red white--text', icon: 'fas fa-times' } : { color: 'color3Text color3--text', icon: 'fas fa-pencil-alt' }
    },
    dirty () {
      return {
        theme: !(
          (this.theme.altHome || '~') === (this.home || '~') &&
          (this.excludes.join(',') === this.theme.excludes)
        ),
        carousel: JSON.stringify(this.carouselItems) !== JSON.stringify(this.carouselItemsOG)
      }
    },
    homeChoices () {
      return [
        { text: 'The Home Page', value: null },
        { text: 'My Events', value: 'tournaments' },
        { text: 'Rankings', value: 'rankings-home' },
        { text: 'Other', value: 'other' }
      ]
    },
    images () {
      return this.carouselItems && this.carouselItems.map(m => {
        return {
          url: m.src
        }
      })
    },
    previewItems () {
      return this.carouselItems && this.carouselItems.map(m => {
        const obj = {
          src: m.src,
          contain: m.contain || false
        }
        if (m.actionText) {
          obj.action = {
            to: m.actionPath,
            text: m.actionText
          }
        }
        return obj
      })
    }
  },
  methods: {
    editMe () {
      this.edit = true
    },
    cancel () {
      if (this.saving.length) return
      this.edit = false
      this.init()
    },
    save () {
      if (this.dirty.theme) {
        this.saving.push('theme')
        this.theme.altHome = this.home === 'other' ? this.alt : this.home
        this.theme.excludes = this.excludes.length ? this.excludes.join(',') : null
        this.$VBL.theme.post(this.theme)
          .then(response => {
            this.$store.dispatch(actions.UPDATE_THEME, response.data)
            this.cancel()
          })
          .catch(error => {
            console.log(error.response)
          })
          .finally(() => {
            this.saving = this.saving.filter(f => f !== 'theme')
            this.cancel()
          })
      }
      if (this.dirty.carousel) {
        this.saving.push('carousel')
        this.$VBL.theme.postCarousel(this.username, this.carouselItems)
          .then(r => {
            this.carouselItems = r.data
            this.carouselItemsOG = JSON.parse(JSON.stringify(r.data))
          })
          .catch(e => console.log(e.response))
          .finally(() => {
            this.saving = this.saving.filter(f => f !== 'carousel')
            this.cancel()
          })
      }
    },
    load () {
      this.loading = true
      this.$store.dispatch(actions.LOAD_THEME, this.username)
        .then(() => {
          this.theme = Object.assign({}, this.getTheme(this.username))
          this.init()
          this.loading = false
        })
      this.$VBL.theme.getCarousel(this.username)
        .then(r => {
          this.carouselItems = r.data
          this.carouselItemsOG = JSON.parse(JSON.stringify(r.data))
        })
        .catch(e => console.log(e.response))
    },
    init () {
      this.slide = null
      this.add = false
      this.carouselItems = JSON.parse(JSON.stringify(this.carouselItemsOG))
      const e = this.theme.excludes
      if (e) {
        this.excludes = e.split(',')
      }
      const a = this.theme.altHome
      if (a) {
        if (a.startsWith('/')) {
          this.home = 'other'
          this.alt = a
        } else {
          this.home = a
        }
      } else {
        this.home = null
      }
    },
    moveImg (i, newI) {
      var img = this.carouselItems.splice(i, 1)
      this.carouselItems.splice(newI, 0, ...img)
      this.slide = newI
    },
    addImage (img) {
      this.carouselItems.push({
        src: img.url,
        actionText: null,
        actionPath: null
      })
      this.slide = this.carouselItems.length - 1
      this.add = false
    },
    removeImg (i) {
      this.carouselItems.splice(i, 1)
      this.slide = null
    },
    updateValue (val) {
      this.editableTheme[this.selectedColorObj[this.selectedProp]] = val.hex
      if (this.theme.isPreview) {
        this.preview()
      }
    },
    undo () {
      this.updateValue({ hex: this.$refs.picker.currentColor })
      this.showMenu = false
    }
  },
  components: {
    ImageLibrary,
    OrganizationImageLibrary
  },
  mounted () {
    this.$nextTick(() => { this.load() })
  }
}
</script>
